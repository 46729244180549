import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useClickAway } from 'react-use';

import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import cx from 'classnames';
import { usePathname } from 'next/navigation';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import Icon from 'components/ui/Icon';
import Img from 'components/ui/Img';
import Portal from 'components/ui/Portal';
import RichText from 'components/ui/RichText';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import AlertSvgIcon from '../AlertSvgIcon';
import iconMap from '../AlertSvgIcon/iconMap';

import styles from './Overlay.module.scss';

const headlineOverrides = {
    renderNode: {
        [BLOCKS.HEADING_3]: (node, children) => {
            return (
                <Text
                    as="h3"
                    className={styles.headline}
                    baseTheme="displayXSmall"
                    themes={{
                        large: 'displaySmall',
                    }}
                    fixWidows
                >
                    {children}
                </Text>
            );
        },
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="h3"
                    className={styles.headline}
                    baseTheme="displayXSmall"
                    themes={{
                        large: 'displaySmall',
                    }}
                    fixWidows
                >
                    {children}
                </Text>
            );
        },
    },
};

const copyOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="p"
                    className={styles.bodyCopy}
                    baseTheme="bodyXSmall"
                    themes={{
                        large: 'bodyMedium',
                    }}
                    fixWidows
                >
                    {children}
                </Text>
            );
        },
    },
};

const Overlay = ({
    image,
    imageLarge,
    headline,
    bodyCopy,
    storeUserViewed,
    cta,
    icon,
    icon2,
}) => {
    const overlayRef = useRef(null);
    const innerRef = useRef(null);

    const pathname = usePathname();

    const [isOpen, toggleOpen] = useState(false);
    const [isVisible, toggleVisibility] = useState(false);

    useClickAway(innerRef, () => {
        handleClose();
    });

    function wait(ms) {
        return new Promise(resolve => {
            setTimeout(resolve, ms);
        });
    }

    function waitFrame() {
        return new Promise(resolve => {
            requestAnimationFrame(resolve);
        });
    }

    async function handleClose() {
        // Save CMS alert id to local storage to avoid rendering alert again
        storeUserViewed();
        toggleVisibility(false);
        clearAllBodyScrollLocks();

        waitFrame().then(() => {
            toggleOpen(false);
        });
    }

    function handleBodyScroll() {
        if (overlayRef.current) {
            disableBodyScroll(overlayRef.current, {
                allowTouchMove: el => el.firstChild,
            });

            // Transition the overlay on
            toggleVisibility(true);

            return;
        }

        // gentle pause to ensure overlay ref exists
        wait(10).then(() => {
            handleBodyScroll();
        });
    }

    useEffect(() => {
        clearAllBodyScrollLocks();
    }, [pathname]);

    // Check local storage for the CMS id of this alert
    useEffect(() => {
        toggleOpen(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleKeyDown(evt) {
        if (evt.keyCode === 27) {
            handleClose();
        }
    }

    function toggleEventListener(method) {
        document[`${method}EventListener`]('keydown', handleKeyDown);
    }

    useEffect(() => {
        isOpen && handleBodyScroll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    useEffect(() => {
        toggleEventListener('add');

        return () => {
            toggleEventListener('remove');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            {isOpen && (
                <Portal>
                    <div
                        ref={overlayRef}
                        className={cx(styles.root, {
                            [styles.hasImage]: image?.url,
                            [styles.isVisible]: isVisible,
                        })}
                    >
                        <div className={styles.overlay}>
                            <div ref={innerRef} className={styles.overlayInner}>
                                <button
                                    onClick={handleClose}
                                    className={styles.closeButton}
                                >
                                    <div className={styles.closeIconWrapper}>
                                        <SvgIcon type="close" />
                                    </div>
                                </button>
                                {image?.url && (
                                    <figure
                                        className={cx(
                                            styles.aspectBox,
                                            styles.imageAspectBox,
                                            styles.imageSmall
                                        )}
                                    >
                                        <Img
                                            className={styles.image}
                                            src={image.url}
                                            fallbackImageWidth={480}
                                            alt={image.description || ''}
                                            customSources={[
                                                {
                                                    breakpoint: 1024,
                                                    imageWidth: 750,
                                                },
                                                {
                                                    imageWidth: 480,
                                                },
                                            ]}
                                            priority
                                        />
                                    </figure>
                                )}
                                {imageLarge?.url && (
                                    <figure
                                        className={cx(
                                            styles.aspectBox,
                                            styles.imageAspectBox,
                                            styles.imageLarge
                                        )}
                                    >
                                        <Img
                                            className={styles.image}
                                            src={imageLarge.url}
                                            fallbackImageWidth={480}
                                            alt={imageLarge.description || ''}
                                            customSources={[
                                                {
                                                    breakpoint: 1024,
                                                    imageWidth: 750,
                                                },
                                                {
                                                    imageWidth: 480,
                                                },
                                            ]}
                                            priority
                                        />
                                    </figure>
                                )}
                                <div className={styles.aspectBox}>
                                    <div className={styles.content}>
                                        <div className={styles.innerContent}>
                                            {icon2?.icon?.url && (
                                                <div
                                                    className={
                                                        styles.iconWrapper
                                                    }
                                                >
                                                    <Icon
                                                        src={icon2.icon?.url}
                                                    />
                                                </div>
                                            )}
                                            {iconMap[icon] &&
                                                !icon2?.icon?.url && (
                                                    <div
                                                        className={
                                                            styles.iconWrapper
                                                        }
                                                    >
                                                        <span
                                                            className={
                                                                styles.icon
                                                            }
                                                        >
                                                            <AlertSvgIcon
                                                                iconSrc={
                                                                    iconMap[
                                                                        icon
                                                                    ]
                                                                }
                                                            />
                                                        </span>
                                                    </div>
                                                )}
                                            <div
                                                className={
                                                    styles.headlineWrapper
                                                }
                                            >
                                                <RichText
                                                    richText={headline}
                                                    overrides={
                                                        headlineOverrides
                                                    }
                                                />
                                            </div>
                                            <div
                                                className={
                                                    styles.bodyCopyWrapper
                                                }
                                            >
                                                <RichText
                                                    richText={bodyCopy}
                                                    overrides={copyOverrides}
                                                />
                                            </div>
                                            {cta?.link && (
                                                <ButtonCTA
                                                    className={styles.cta}
                                                    {...cta}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Portal>
            )}
        </Fragment>
    );
};

Overlay.propTypes = {
    icon: PropTypes.string,
    icon2: PropTypes.object,
    headline: PropTypes.object.isRequired,
    bodyCopy: PropTypes.object.isRequired,
    cta: PropTypes.object,
    image: PropTypes.object,
    imageLarge: PropTypes.object,
    sys: PropTypes.object.isRequired,
    storeUserViewed: PropTypes.func.isRequired,
};

export default Overlay;
