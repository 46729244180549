'use client';

import React, { useCallback, useEffect, useState } from 'react';

import { usePathname } from 'next/navigation';
import { pathToRegexp } from 'path-to-regexp';
import PropTypes from 'prop-types';

import { localStorageSupport, saveToLocalStorage } from 'utils';

import Card from './Card';
import Overlay from './Overlay';
import SignUp from './SignUp';

export const ALERT_STATE = {
    CLOSED: 1,
    MINIMIZED: 2,
};

const alertMap = {
    AlertOverlay: Overlay,
    AlertCard: Card,
    AlertSignUp: SignUp,
};

const Alerts = ({ alerts }) => {
    const pathname = usePathname();

    const [alert, updateAlert] = useState(null);
    const [userLocation, setUserLocation] = useState(null);

    const [hasOneTrustInteracted, setOneTrustInteracted] = useState(false);

    const uid = `tcca_alert_${alert?.sys?.id}_${new Date(
        alert?.sys?.publishedAt
    ).getTime()}`; // add publishedAt to reset when changes

    const disallowedQueryParams = [
        'utm_campaign',
        'utm_medium',
        'utm_source',
        '_kx',
        'fbid',
    ];

    const url = new URL(pathname, 'https://www.tillamook.com');

    disallowedQueryParams.forEach(param => {
        url.searchParams.delete(param);
    });

    const matchIncluded = (isGlobal, includedRoutes) => {
        // If global, we are clear to proceed
        if (isGlobal) {
            return true;
        }

        // No included routes = no render
        if (!includedRoutes?.length) {
            return false;
        }

        // Wrap in try/catch to avoid any errors with misformatted routes
        // If in doubt, do not render
        try {
            return pathToRegexp(includedRoutes)?.test(pathname);
        } catch {
            console.warn(
                `There was an issue with the included alert routes: ${includedRoutes}.`
            );
            return false;
        }
    };

    const matchExcluded = excludedRoutes => {
        // If no excluded routes, there are no exclusions here
        if (!excludedRoutes?.length) {
            return false;
        }

        // Wrap in try/catch to avoid any errors with misformatted routes
        // If in doubt, do not render
        try {
            return pathToRegexp(excludedRoutes)?.test(pathname);
        } catch {
            console.warn(
                `There was an issue with the excluded alert routes: ${excludedRoutes}.`
            );
            return true;
        }
    };

    const matchAlerts = () => {
        return alerts
            ?.filter(alert => alert !== null)
            ?.find(
                ({
                    isGlobal,
                    includedRoutes = { routes: [] },
                    excludedRoutes = { routes: [] },
                    regionCategory,
                    regions,
                }) => {
                    return (
                        matchIncluded(isGlobal, includedRoutes?.routes) &&
                        !matchExcluded(excludedRoutes?.routes) &&
                        matchRegion(regionCategory, regions)
                    );
                }
            );
    };

    const matchRegion = (regionCategory, regions) => {
        const viewerCountry = userLocation?.viewerCountry || 'Unknown';
        const viewerCountryRegion =
            userLocation?.viewerCountryRegion || 'Unknown';

        // Map against nested regions object
        // Contentful goes crazy if this field is not an object
        const states = regions?.regions || [];

        // Check for required region category before proceeding
        if (!regionCategory) {
            console.warn(`Region category required for alert render.`);
            return false;
        }

        if (regionCategory === 'Global') {
            console.info('Global match', viewerCountry, viewerCountryRegion);
            return true;
        }

        if (regionCategory === 'Outside US' && viewerCountry !== 'US') {
            console.info(`Outside US`, viewerCountry, viewerCountryRegion);
            return true;
        }

        if (
            (regionCategory === 'US' ||
                regionCategory === 'Core' ||
                regionCategory === 'Growth' ||
                regionCategory === 'Frontier') &&
            viewerCountry === 'US'
        ) {
            const match = states.includes(viewerCountryRegion);

            if (match) {
                return match;
            }
        }

        // Nothing matches
        return false;
    };

    const handleAlerts = () => {
        let matchedAlert = matchAlerts() || null;

        if (matchedAlert !== alert) {
            updateAlert(matchedAlert);
        }
    };

    useEffect(() => {
        const match = document?.cookie?.match(
            /(?:(?:^|.*;\s*)OptanonAlertBoxClosed\s*=\s*([^;]*).*$)|^.*$/,
            '$1'
        );

        if (match?.[1]) {
            setOneTrustInteracted(true);
        }

        if (!window.OptanonWrapper) {
            window.OptanonWrapper = () => {
                window.OneTrust.OnConsentChanged(() => {
                    setOneTrustInteracted(true);
                });
            };
        }
    }, []);

    useEffect(() => {
        async function locate() {
            const response = await fetch(
                'https://d2k6913brarspg.cloudfront.net/geo'
            );

            setUserLocation({
                viewerCountry: response.headers.get(
                    'cloudfront-viewer-country'
                ),
                viewerCountryRegion: response.headers.get(
                    'cloudfront-viewer-country-region'
                ),
                viewerCity: response.headers.get('cloudfront-viewer-city'),
                viewerLat: response.headers.get('cloudfront-viewer-latitude'),
                viewerLong: response.headers.get('cloudfront-viewer-longitude'),
            });
        }
        locate();
    }, []);

    useEffect(() => {
        if (userLocation !== null && hasOneTrustInteracted) {
            handleAlerts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userLocation, pathname, hasOneTrustInteracted]);

    let RenderedAlert = alertMap[alert?.__typename] || null;

    const storeUserViewed = useCallback(() => {
        saveToLocalStorage(uid, ALERT_STATE.CLOSED);
    }, [uid]);

    const storeUserMinimized = useCallback(
        isMinimized => {
            saveToLocalStorage(uid, isMinimized ? ALERT_STATE.MINIMIZED : 0);
        },
        [uid]
    );

    if (
        localStorageSupport() &&
        parseInt(localStorage.getItem(uid), 10) === ALERT_STATE.CLOSED
    ) {
        return null;
    }

    if (RenderedAlert) {
        const isUserStoreMinimized =
            localStorageSupport() &&
            parseInt(localStorage.getItem(uid), 10) === ALERT_STATE.MINIMIZED;

        return (
            // Portal or Tunnel will be rendered based on component
            <RenderedAlert
                {...alert}
                uid={uid}
                isUserStoreMinimized={isUserStoreMinimized}
                storeUserMinimized={storeUserMinimized}
                storeUserViewed={storeUserViewed}
            />
        );
    }

    return null;
};

Alerts.propTypes = {
    attributes: PropTypes.object,
    alerts: PropTypes.array,
};

export default Alerts;
