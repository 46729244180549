import { svgElementAttributes } from 'svg-element-attributes';

const tags = Object.keys(svgElementAttributes);

const allowList = tags.reduce((acc, tag) => {
    acc[tag] = svgElementAttributes[tag];
    return acc;
}, {});

export default allowList;
