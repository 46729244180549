import React, { Fragment, useEffect, useState } from 'react';

import cx from 'classnames';
import { useRouter } from 'next/navigation';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import Button from 'components/ui/Button';
import ButtonCTA from 'components/ui/ButtonCTA';
import Icon from 'components/ui/Icon';
import Portal from 'components/ui/Portal';
import RichText from 'components/ui/RichText';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import { getEntryLink } from 'utils/getEntryLink';

import AlertSvgIcon from '../AlertSvgIcon';
import iconMap from '../AlertSvgIcon/iconMap';

import styles from './Card.module.scss';

const headlineOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="h3"
                    className={styles.headline}
                    baseTheme="displayXSmall"
                    themes={{ large: 'displaySmall' }}
                    fixWidows
                >
                    {children}
                </Text>
            );
        },
    },
};

const copyOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="p"
                    className={styles.bodyCopy}
                    baseTheme="bodyXSmall"
                    themes={{
                        large: 'bodyMedium',
                    }}
                    fixWidows
                >
                    {children}
                </Text>
            );
        },
    },
};

const Card = ({
    icon,
    icon2,
    headline,
    bodyCopy,
    cta,
    sys,
    storeUserViewed,
}) => {
    const [isOpen, toggleOpen] = useState(false);
    const [isVisible, toggleVisibility] = useState(false);
    const router = useRouter();

    function wait(ms) {
        return new Promise(resolve => {
            setTimeout(resolve, ms);
        });
    }

    // Check local storage for the CMS id of this alert
    useEffect(() => {
        // Display alert IF alert id is not in local storage
        toggleOpen(true);
        toggleVisibility(true);
    }, [sys.id]);

    function handleClose() {
        // Save CMS alert id to local storage to avoid rendering alert again
        storeUserViewed();
        toggleVisibility(false);

        // Transition card off before render
        wait(600).then(() => {
            toggleOpen(false);
        });
    }

    const handleCTAClick = async e => {
        if (e) {
            e.preventDefault();
            storeUserViewed();
            handleRouting();
        }
    };

    const handleRouting = () => {
        const { link } = cta || {};

        if (link?.__typename === 'ExternalLink') {
            window.open(link.href, '_blank');
        } else {
            const { href, ...rest } = getEntryLink(link);
            // internal link
            router.push(href, { ...rest });
        }
    };

    return (
        <Fragment>
            {isOpen && (
                <Portal>
                    <div
                        className={cx(
                            styles.root,
                            !isVisible && styles.isClosing
                        )}
                    >
                        <div className={styles.card}>
                            <Button
                                theme=""
                                onClick={handleClose}
                                className={styles.closeButton}
                            >
                                <div className={styles.closeIconBuffer}>
                                    <div className={styles.closeIconWrapper}>
                                        <SvgIcon type="close" />
                                    </div>
                                </div>
                            </Button>
                            {icon2?.icon?.url && (
                                <div className={styles.icon}>
                                    <Icon src={icon2?.icon?.url} />
                                </div>
                            )}
                            {iconMap[icon] && !icon2?.icon?.url && (
                                <div className={styles.icon}>
                                    <AlertSvgIcon iconSrc={iconMap[icon]} />
                                </div>
                            )}
                            <div className={styles.headlineWrapper}>
                                <RichText
                                    richText={headline}
                                    overrides={headlineOverrides}
                                />
                            </div>
                            <div className={styles.bodyCopyWrapper}>
                                <RichText
                                    richText={bodyCopy}
                                    overrides={copyOverrides}
                                />
                            </div>
                            {cta?.link && (
                                <ButtonCTA onClick={handleCTAClick} {...cta} />
                            )}
                        </div>
                    </div>
                </Portal>
            )}
        </Fragment>
    );
};

Card.propTypes = {
    headline: PropTypes.object.isRequired,
    bodyCopy: PropTypes.object.isRequired,
    sys: PropTypes.object.isRequired,
    cta: PropTypes.object,
    icon: PropTypes.string,
    icon2: PropTypes.object,
    storeUserViewed: PropTypes.func.isRequired,
};

export default Card;
