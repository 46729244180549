import React, { useEffect, useRef } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { useGet } from 'hooks/useFetch';
import useWait from 'hooks/useWait';

import styles from './Animation.module.scss';

const Animation = ({
    index,
    className,
    animationUrl,
    isPlaying,
    delay,
    onLoad,
}) => {
    delay ??= 0;

    const animationsContainerRef = useRef([]);
    const animationRef = useRef();
    const response = useGet(animationUrl);

    const wait = useWait();

    useEffect(() => {
        const name = `step${index}`;
        let lottie;
        const load = async () => {
            if (!response) {
                return null;
            }
            // Lazy load animation library
            lottie = await import(
                /* webpackPrefetch: true, webpackChunkName: 'lottie-web' */ 'lottie-web'
            );

            animationRef.current = lottie.loadAnimation({
                container: animationsContainerRef.current, // the dom element
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: response, // the animation data
                name: name,
            });

            onLoad && animationRef.current && onLoad();
        };

        load();

        return () => {
            animationRef?.current?.destroy(); // clean up
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);

    useEffect(() => {
        if (!animationRef.current) return;

        if (isPlaying) {
            animationRef.current.goToAndStop(0);

            wait(delay).then(() => {
                animationRef.current.play();
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delay, isPlaying]);

    return (
        <figure
            className={cx(styles.root, className)}
            ref={animationsContainerRef}
        ></figure>
    );
};

Animation.propTypes = {
    animationUrl: PropTypes.string.isRequired,
    className: PropTypes.string,
    delay: PropTypes.number,
    index: PropTypes.number,
    isPlaying: PropTypes.bool,
    onLoad: PropTypes.func,
};

export default Animation;
