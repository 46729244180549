'use client';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import Button from 'components/ui/Button';
import Icon from 'components/ui/Icon';
import Link from 'components/ui/Link';
import ReCaptchaLegal from 'components/ui/ReCaptchaLegal';
import RichText from 'components/ui/RichText';
import Spinner from 'components/ui/Spinner';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import useSubscribe from 'hooks/useSubscribe';
import { useWait } from 'hooks/useWait';
import { EMAIL_REGEX } from 'utils';
import { event } from 'utils/gtm';

import SignUpBubble from './SignUpBubble';

import styles from './SignUp.module.scss';

const headlineOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    className={styles.headline}
                    baseTheme={'displayXSmall'}
                    themes={{ medium: 'displaySmall' }}
                >
                    {children}
                </Text>
            );
        },
    },
};

const copyOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    className={styles.copy}
                    baseTheme={'bodyXSmall'}
                    themes={{ medium: 'bodySmall' }}
                    fixWidows
                >
                    {children}
                </Text>
            );
        },
    },
};

const successOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    className={styles.successHeadline}
                    baseTheme={'displayXSmall'}
                    themes={{ medium: 'displaySmall' }}
                >
                    {children}
                </Text>
            );
        },
    },
};

const SignUp = ({
    sys,
    klaviyoSource,
    iconImage,
    headline,
    copy,
    successMessage,
    storeUserViewed,
    isUserStoreMinimized,
    storeUserMinimized,
    autoMinimizeDelay,
}) => {
    autoMinimizeDelay ??= 10;

    const dialogRef = useRef(null);

    const [isOpen, setIsOpen] = useState(false);
    const [isClosing, setClosing] = useState(false);
    const [isMinimized, setIsMinimized] = useState(false);
    const [isUserStoreMinimizedOnLoad, setIsUserStoreMinimizedOnLoad] =
        useState(isUserStoreMinimized);
    const [isInteractingWindow, setIsInteractingWindow] = useState(false);
    const [isInteractingDialog, setIsInteractingDialog] = useState(false);
    const [isTimerCancelled, setIsTimerCancelled] = useState(false);

    const source = klaviyoSource;
    const icon = iconImage?.icon;

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({});

    const { isSuccess, isSubmitting, isSubscribed, subscribe } =
        useSubscribe(source);

    const subscribeWithData = async formData => {
        delete formData.terms;

        await subscribe(formData);
    };

    const wait = useWait();

    const handleMinimized = useCallback(
        isMinimized => {
            setIsUserStoreMinimizedOnLoad(false);
            storeUserMinimized(isMinimized);
            setIsMinimized(isMinimized);
        },
        [storeUserMinimized]
    );

    const handleCloseClick = e => {
        e.preventDefault();
        isSuccess ? setClosing(true) : handleMinimized(true);
    };

    const handleBubbleClick = () => {
        handleMinimized(false);
    };

    useEffect(() => {
        if (isSuccess) {
            storeUserViewed();
            event('new_subscriber', {
                formLocation: 'AlertSignUp',
                source: source,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    useEffect(() => {
        wait(600).then(() => {
            setIsOpen(true);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sys.id]);

    const handleAnimation = e => {
        if (/^SignUp_close/i.test(e.animationName)) {
            setIsOpen(false);
            setClosing(false);
        }
    };

    // AUTO MINIMIZE TIMER
    // detects if user is interacting but not using the dialog
    // a timer of `autoMinimizeDelay` seconds auto minimizes the dialog

    useEffect(() => {
        // detect scroll or mouse movement on window
        // stop detecting if interacting with dialog or timer is cancelled
        if (isInteractingDialog || isTimerCancelled) {
            return;
        }

        const handleInteraction = () => {
            setIsInteractingWindow(true);
        };

        window.addEventListener('scroll', handleInteraction);
        window.addEventListener('mousemove', handleInteraction);

        return () => {
            window.removeEventListener('scroll', handleInteraction);
            window.removeEventListener('mousemove', handleInteraction);
        };
    }, [
        isMinimized,
        isTimerCancelled,
        isInteractingDialog,
        isUserStoreMinimizedOnLoad,
    ]);

    useEffect(() => {
        // if minimized or store minimized cancel timer
        if (isMinimized || isUserStoreMinimizedOnLoad) {
            setIsTimerCancelled(true);
            return;
        }
    }, [isMinimized, isUserStoreMinimizedOnLoad]);

    useEffect(() => {
        // start timer if user is interacting with the window but not dialog
        let timer = 0;

        if (isInteractingWindow && !isInteractingDialog && !isTimerCancelled) {
            timer = setTimeout(() => {
                handleMinimized(true);
            }, autoMinimizeDelay * 1000);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [
        autoMinimizeDelay,
        handleMinimized,
        isInteractingWindow,
        isTimerCancelled,
        isInteractingDialog,
    ]);

    return (
        <div
            ref={dialogRef}
            className={cx(styles.root, {
                [styles.isOpen]: isOpen,
                [styles.isClosing]: isClosing,
                [styles.isMinimized]: isMinimized,
                [styles.isUserStoreMinimized]: isUserStoreMinimizedOnLoad,
            })}
            tabIndex={-1}
            onAnimationEnd={isClosing ? handleAnimation : null}
            onMouseEnter={() => {
                setIsInteractingDialog(true);
            }}
            onMouseLeave={() => {
                setIsInteractingDialog(false);
            }}
        >
            <form
                className={styles.form}
                onSubmit={handleSubmit(subscribeWithData)}
            >
                <div className={styles.icon}>
                    <Icon src={icon?.url} />
                </div>
                <div className={styles.headlineWrapper}>
                    {headline?.json && (
                        <RichText
                            richText={headline}
                            overrides={headlineOverrides}
                        />
                    )}
                    {!headline?.json && (
                        <Text
                            className={styles.headline}
                            baseTheme={'displayXSmall'}
                            themes={{ medium: 'displaySmall' }}
                        >
                            WE DISH OUT THE GOOD STUFF
                        </Text>
                    )}
                </div>
                <div className={styles.copyWrapper}>
                    {copy?.json && (
                        <RichText richText={copy} overrides={copyOverrides} />
                    )}
                    {!copy?.json && (
                        <Text
                            className={styles.copy}
                            baseTheme={'bodyXSmall'}
                            themes={{ medium: 'bodySmall' }}
                            fixWidows
                        >
                            Sign up for our newsletter to stay in the know about
                            tasty recipes, our best offers, special releases,
                            and more.
                        </Text>
                    )}
                </div>
                <div className={styles.inputs}>
                    <Text className={styles.inputWrap} baseTheme="bodyMedium">
                        <input
                            {...register('email_address', {
                                required: true,
                                pattern: EMAIL_REGEX,
                            })}
                            className={cx(styles.input, {
                                [styles.inputError]:
                                    errors.email_address || isSubscribed,
                            })}
                            type="email"
                            placeholder="Email"
                            // cancel timer if user interacts with input
                            onClick={() => {
                                setIsTimerCancelled(true);
                            }}
                            onKeyUp={() => {
                                setIsTimerCancelled(true);
                            }}
                        />
                    </Text>
                    <div className={styles.inputLockup}>
                        <Text
                            className={cx(
                                styles.inputWrap,
                                styles.zipcodeInput
                            )}
                            baseTheme="bodyMedium"
                        >
                            <input
                                {...register('location[zip]', {
                                    required: true,
                                    pattern: /^([0-9]{5})$/,
                                })}
                                className={cx(styles.input, {
                                    [styles.inputError]:
                                        errors?.location?.zip || isSubscribed,
                                })}
                                type="text"
                                placeholder="Zip code"
                                // cancel timer if user interacts with input
                                onClick={() => {
                                    setIsTimerCancelled(true);
                                }}
                                onFocus={() => {
                                    setIsTimerCancelled(true);
                                }}
                            />
                        </Text>
                        <div
                            className={cx(styles.submitButtonWrap, {
                                [styles['submitButtonWrap__isLoading']]:
                                    isSubmitting,
                            })}
                        >
                            <Button
                                type="submit"
                                className={styles.submitButton}
                            >
                                <SvgIcon
                                    type="arrowRight"
                                    className={styles.submitButtonIcon}
                                />
                                <span className="sr-only">Submit</span>
                            </Button>
                            <div className={styles.buttonSpinner}>
                                <Spinner />
                            </div>
                        </div>
                    </div>
                    {(errors.email_address ||
                        errors?.location?.zip ||
                        isSubscribed) && (
                        <Text
                            baseTheme="bodyXSmall"
                            className={styles.joinError}
                            as="ul"
                        >
                            {errors.email_address && <li>Email is invalid</li>}
                            {errors?.location?.zip && (
                                <li>Zip code is invalid</li>
                            )}
                            {isSubscribed && (
                                <li>
                                    Oops, looks like you&rsquo;re already
                                    subscribed.
                                </li>
                            )}
                        </Text>
                    )}
                    <Text
                        baseTheme="bodyXSmall"
                        as="p"
                        className={cx(styles.checkboxWrapper, {
                            [styles.hide]: isSuccess,
                        })}
                    >
                        <label
                            className={cx(styles.checkbox, {
                                [styles.checkboxError]: errors.terms,
                            })}
                        >
                            I agree to receive emails for marketing purposes.
                            Read&nbsp;
                            <Link
                                href="/privacy-policy"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Privacy Policy
                            </Link>
                            <input
                                type="checkbox"
                                {...register('terms', {
                                    required: true,
                                })}
                                // cancel timer if user interacts with input
                                onClick={() => {
                                    setIsTimerCancelled(true);
                                }}
                                onFocus={() => {
                                    setIsTimerCancelled(true);
                                }}
                            />
                            <span className={styles.checkmark} />
                        </label>
                    </Text>
                </div>
                <div className={styles.reCaptchaLegal}>
                    <ReCaptchaLegal />
                </div>
                <div
                    className={cx(styles.success, {
                        [styles.successComplete]: isSuccess,
                    })}
                >
                    <div className={styles.successIcon}>
                        <svg
                            width="80"
                            height="80"
                            viewBox="0 0 80 80"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M71.6507 36.9713L72.9982 27.6176L65.028 22.509L61.0928 13.9146H51.6185L43.6497 11.5522V22.5297C47.4824 23.7752 50.8224 26.2021 53.1911 29.4625C55.5598 32.7228 56.8356 36.6494 56.8356 40.6794C56.8356 44.7093 55.5598 48.6359 53.1911 51.8963C50.8224 55.1566 47.4824 57.5835 43.6497 58.829V69.2025L52.7389 71.8635L58.942 64.7242L68.033 62.0628L69.3816 52.7101L75.5871 45.5681L71.6507 36.9713Z"
                                fill="#41B6E6"
                            />
                            <path
                                d="M35.5381 47.5525C35.0543 47.5525 34.5898 47.3627 34.2444 47.0238L30.2296 43.0829C30.0566 42.913 29.9186 42.7107 29.8237 42.4875C29.7288 42.2643 29.6788 42.0246 29.6766 41.7821C29.6743 41.5396 29.7199 41.299 29.8106 41.074C29.9013 40.8491 30.0355 40.6442 30.2054 40.4712C30.3753 40.2981 30.5776 40.1601 30.8008 40.0653C31.024 39.9704 31.2637 39.9204 31.5062 39.9181C31.7488 39.9159 31.9894 39.9614 32.2143 40.0521C32.4392 40.1428 32.6441 40.277 32.8171 40.4469L35.5121 43.0923L44.2878 34.1254C44.6308 33.7773 45.0977 33.5794 45.5864 33.5749C46.0751 33.5704 46.5456 33.7599 46.8948 34.1017C47.2441 34.4434 47.4437 34.9097 47.4498 35.3984C47.4559 35.887 47.2681 36.3582 46.9275 36.7086L36.8579 46.9973C36.6881 47.1708 36.4858 47.3092 36.2625 47.4044C36.0393 47.4997 35.7994 47.55 35.5567 47.5525H35.5381Z"
                                fill="#001E60"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M41.6827 20.3721C37.6738 19.5815 33.5204 19.9981 29.7484 21.5691C25.9764 23.1402 22.7553 25.7951 20.4929 29.1977C18.2306 32.6001 17.0287 36.5972 17.0391 40.683C17.0467 46.1522 19.2263 51.3945 23.0987 55.2568C26.9713 59.1194 32.2197 61.2854 37.6892 61.2785C41.7747 61.2781 45.7682 60.0658 49.1645 57.7949C52.5612 55.5236 55.2077 52.2957 56.769 48.5195C58.3302 44.7434 58.736 40.589 57.9349 36.5822C57.1339 32.5753 55.162 28.8963 52.2689 26.0107C49.3758 23.1251 45.6916 21.1628 41.6827 20.3721ZM30.9018 24.3385C34.1258 22.9957 37.6758 22.6397 41.1022 23.3154C44.5287 23.9912 47.6776 25.6684 50.1503 28.1348C52.6231 30.6011 54.3085 33.7456 54.9931 37.1703C55.6778 40.595 55.331 44.1458 53.9966 47.3733C52.6622 50.6008 50.4002 53.3598 47.497 55.301C44.5937 57.2423 41.1798 58.2785 37.6873 58.2785H37.6854C33.0115 58.2844 28.5266 56.4334 25.2173 53.1327C21.908 49.832 20.0454 45.352 20.0391 40.678L20.0391 40.6761C20.03 37.1836 21.0574 33.767 22.9911 30.8587C24.9248 27.9505 27.6779 25.6813 30.9018 24.3385Z"
                                fill="#001E60"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M36.8779 7.2371C37.3712 6.92097 38.0034 6.92097 38.4966 7.2371L46.0954 12.107H55.1303C55.717 12.107 56.2498 12.4491 56.4941 12.9825L60.2459 21.1764L67.8452 26.0471C68.3403 26.3645 68.6043 26.9418 68.5204 27.5239L67.2359 36.4404L70.9884 44.6361C71.2334 45.1711 71.1428 45.8001 70.7568 46.2443L64.8407 53.0533L63.5552 61.9693C63.4713 62.5508 63.0558 63.0298 62.4919 63.1948L53.8232 65.7325L47.9088 72.5398C47.5244 72.9822 46.9176 73.1602 46.3551 72.9956L37.6873 70.458L29.0197 72.9956C28.4573 73.1602 27.8504 72.9822 27.466 72.5398L21.5513 65.7325L12.8826 63.1948C12.3188 63.0298 11.9032 62.5508 11.8194 61.9693L10.5338 53.0533L4.61773 46.2443C4.23173 45.8001 4.14118 45.1711 4.38618 44.6361L8.13865 36.4404L6.8541 27.5239C6.77025 26.9418 7.03425 26.3645 7.52935 26.0471L15.1286 21.1764L18.8804 12.9825C19.1247 12.4491 19.6576 12.107 20.2443 12.107H29.2791L36.8779 7.2371ZM37.6873 10.2816L30.5279 14.8699C30.2863 15.0247 30.0054 15.107 29.7185 15.107H21.2072L17.6729 22.826C17.5531 23.0875 17.3606 23.3091 17.1185 23.4644L9.96113 28.0518L11.1709 36.4496C11.212 36.7349 11.1701 37.0259 11.0501 37.288L7.5162 45.0062L13.0878 51.4187C13.2767 51.6361 13.3991 51.9033 13.4402 52.1884L14.651 60.5866L22.8164 62.9769C23.0922 63.0576 23.3389 63.2158 23.5273 63.4327L29.0998 69.8462L37.2658 67.4554C37.541 67.3749 37.8335 67.3749 38.1087 67.4554L46.275 69.8462L51.8472 63.4327C52.0356 63.2158 52.2824 63.0576 52.5581 62.9769L60.7235 60.5866L61.9344 52.1884C61.9755 51.9033 62.0978 51.6361 62.2867 51.4187L67.8583 45.0062L64.3244 37.288C64.2045 37.0259 64.1625 36.7349 64.2036 36.4496L65.4134 28.0518L58.2561 23.4644C58.0139 23.3091 57.8215 23.0875 57.7017 22.826L54.1673 15.107H45.656C45.3691 15.107 45.0882 15.0247 44.8467 14.8699L37.6873 10.2816Z"
                                fill="#001E60"
                            />
                        </svg>
                    </div>
                    <div className={styles.headlineWrapper}>
                        {successMessage?.json && (
                            <RichText
                                richText={successMessage}
                                overrides={successOverrides}
                            />
                        )}
                        {!successMessage?.json && (
                            <Text
                                className={styles.successHeadline}
                                baseTheme={'displayXSmall'}
                                themes={{ medium: 'displaySmall' }}
                                fixWidows
                            >
                                You’re
                                <br />
                                all set
                            </Text>
                        )}
                    </div>
                    <div className={styles.copyWrapper}>
                        <Text
                            baseTheme={'bodyXSmall'}
                            className={styles.successCopy}
                            fixWidows
                        >
                            Thanks for joining our community
                            <br />
                            of food lovers and farmers.
                        </Text>
                    </div>
                </div>
                <Button
                    theme=""
                    className={styles.closeButton}
                    value="cancel"
                    onClick={handleCloseClick}
                >
                    <div className={styles.closeIconBuffer}>
                        <div className={styles.closeIconWrapper}>
                            <svg
                                width="29"
                                height="28"
                                viewBox="0 0 29 28"
                                fill="none"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M21.2022 9.81654C21.3975 9.62128 21.3975 9.30469 21.2022 9.10943L19.4344 7.34167C19.2392 7.1464 18.9226 7.1464 18.7273 7.34167L14.5431 11.5259L10.3602 7.34304C10.1649 7.14777 9.84832 7.14777 9.65306 7.34303L7.88529 9.1108C7.69003 9.30606 7.69003 9.62265 7.88529 9.81791L12.0682 14.0008L7.88503 18.184C7.68977 18.3792 7.68976 18.6958 7.88503 18.8911L9.65279 20.6588C9.84806 20.8541 10.1646 20.8541 10.3599 20.6588L14.5431 16.4757L18.7276 20.6602C18.9229 20.8555 19.2394 20.8555 19.4347 20.6602L21.2025 18.8924C21.3977 18.6972 21.3977 18.3806 21.2025 18.1853L17.0179 14.0008L21.2022 9.81654Z"
                                    fill="#001E60"
                                />
                            </svg>
                        </div>
                    </div>
                </Button>
            </form>
            <SignUpBubble
                onClick={handleBubbleClick}
                isActive={isMinimized || isUserStoreMinimizedOnLoad}
            />
        </div>
    );
};

SignUp.propTypes = {
    sys: PropTypes.object,
    klaviyoSource: PropTypes.string,
    iconImage: PropTypes.object,
    headline: PropTypes.object,
    copy: PropTypes.object,
    successMessage: PropTypes.object,
    storeUserViewed: PropTypes.func,
    isUserStoreMinimized: PropTypes.bool,
    storeUserMinimized: PropTypes.func,
    autoMinimizeDelay: PropTypes.number,
};

export default SignUp;
