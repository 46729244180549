'use client';

import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import xss from 'xss';

import Animation from 'components/ui/Animation';

import allowList from './svgAllowList';

import styles from './Icon.module.scss';

const cache = {};

const Icon = ({ className, src, height, tag: Tag, width, contained }) => {
    Tag ??= 'span';
    contained ??= false;

    const [icon, setIcon] = useState(cache[src]);

    useEffect(() => {
        async function loadIcon() {
            if (cache[src]) {
                // loaded from cache
                if (icon !== cache[src]) {
                    setIcon(cache[src]);
                }
                return;
            }
            if (src) {
                setIcon(null);

                try {
                    const response = await fetch(src);
                    const text = await response.text();

                    let clean;

                    clean = xss(text, { allowList });

                    cache[src] = clean;

                    setIcon(clean);
                } catch (e) {
                    console.warn(`Missing Icon: ${src}`);
                }
            }
        }
        loadIcon();
        return function unmount() {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [src]);

    if (src == null) {
        return null;
    }

    const Wrapper = contained ? 'div' : React.Fragment;
    const wrapperProps = contained ? { className: styles.iconWrapper } : {};

    return (
        <Wrapper {...wrapperProps}>
            {/\.json$/g.test(src) ? (
                <Animation animationUrl={src} />
            ) : (
                <Tag
                    className={cx('svg-icon', className)}
                    dangerouslySetInnerHTML={{ __html: icon }}
                    style={{
                        width,
                        height,
                    }}
                />
            )}
        </Wrapper>
    );
};

Icon.propTypes = {
    contained: PropTypes.bool,
    src: PropTypes.string,
    className: PropTypes.string,
    height: PropTypes.string,
    tag: PropTypes.string,
    width: PropTypes.string,
};

export default Icon;
