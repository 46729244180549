import { useEffect, useState } from 'react';

export const useFetch = (url, options) => {
    const [response, setResponse] = useState();
    useEffect(() => {
        const load = async () => {
            const response = await fetch(url, options).then(res => res.json());
            setResponse(response);
        };
        load();
    }, [url]);

    return response;
};

export const useGet = (url, options = {}) => {
    options.method = 'get';
    return useFetch(url, options);
};
export const usePost = (url, options = {}) => {
    options.method = 'post';
    return useFetch(url, options);
};
export const useDelete = (url, options = {}) => {
    options.method = 'delete';
    return useFetch(url, options);
};
export const usePut = (url, options = {}) => {
    options.method = 'put';
    return useFetch(url, options);
};
export const usePatch = (url, options = {}) => {
    options.method = 'patch';
    return useFetch(url, options);
};
export const useHead = (url, options = {}) => {
    options.method = 'head';
    return useFetch(url, options);
};
export const useOptions = (url, options = {}) => {
    options.method = 'options';
    return useFetch(url, options);
};
