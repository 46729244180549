'use client';

import React, { Suspense } from 'react';

import { AppProgressBar as ProgressBar } from 'next-nprogress-bar';

import styles from './PageLoader.module.scss';

const PageLoader = () => {
    return (
        <Suspense>
            <ProgressBar
                options={{
                    speed: 500,
                    minimum: 0.2,
                    trickle: true,
                    trickleSpeed: 100,
                    showSpinner: false,
                    template: `<div class="${styles.loader}" role="bar"></div>`,
                }}
            />
        </Suspense>
    );
};

export default PageLoader;
