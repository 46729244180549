'use client';

import React, { useEffect, useState } from 'react';

import cx from 'clsx';
import PropTypes from 'prop-types';

import styles from './SignUpBubble.module.scss';

const SignUpBubble = ({ isActive, onClick }) => {
    const [canClose, setCanClose] = useState(false);

    useEffect(() => {
        if (isActive) {
            setCanClose(true);
        }
    }, [isActive]);

    return (
        <button
            onClick={onClick}
            className={cx(styles.root, {
                [styles.canClose]: canClose,
                [styles.isActive]: isActive,
            })}
        >
            <svg
                width="50"
                height="28"
                viewBox="0 0 50 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={styles.icon}
            >
                <path
                    d="M10.5312 10.1815C10.1811 8.03526 11.6371 6.01157 13.7833 5.66143L37.0997 1.85754C39.2459 1.5074 41.2696 2.96341 41.6197 5.10962L43.733 18.0632C44.0831 20.2094 42.6271 22.2331 40.4809 22.5832L17.1645 26.3871C15.0183 26.7372 12.9946 25.2812 12.6445 23.135L10.5312 10.1815Z"
                    fill="#BADAF3"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.75394 10.3083C9.33378 7.73283 11.081 5.30441 13.6564 4.88424L36.9728 1.08035C39.5483 0.660187 41.9767 2.4074 42.3969 4.98285L44.5101 17.9364C44.9303 20.5119 43.1831 22.9403 40.6077 23.3605L17.2913 27.1643C14.7158 27.5845 12.2874 25.8373 11.8672 23.2618L9.75394 10.3083ZM13.91 6.43867C12.1931 6.71878 11.0283 8.33773 11.3084 10.0547L13.4216 23.0082C13.7017 24.7252 15.3207 25.89 17.0377 25.6099L40.3541 21.806C42.071 21.5259 43.2358 19.907 42.9557 18.19L40.8425 5.23644C40.5623 3.51947 38.9434 2.35467 37.2264 2.63478L13.91 6.43867Z"
                    fill="#001E60"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.6094 9.26325C14.8356 8.89338 15.3188 8.7769 15.6886 9.00308L25.7362 15.1473C26.7343 15.7577 28.0287 15.5465 28.7811 14.6506L36.3549 5.63154C36.6337 5.29953 37.1289 5.2564 37.4609 5.53521C37.7929 5.81401 37.836 6.30917 37.5572 6.64118L29.9834 15.6602C28.7315 17.1509 26.5779 17.5023 24.9171 16.4867L14.8696 10.3425C14.4997 10.1163 14.3832 9.63312 14.6094 9.26325Z"
                    fill="#001E60"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M39.6544 18.9814C39.4282 19.3512 38.945 19.4677 38.5752 19.2415L30.3351 14.2027C29.9653 13.9765 29.8488 13.4933 30.075 13.1234C30.3012 12.7535 30.7843 12.6371 31.1542 12.8632L39.3942 17.9021C39.7641 18.1283 39.8806 18.6115 39.6544 18.9814ZM16.8029 22.7094C16.4709 22.4306 16.4278 21.9355 16.7066 21.6034L22.9179 14.2069C23.1967 13.8749 23.6918 13.8318 24.0238 14.1106C24.3559 14.3894 24.399 14.8846 24.1202 15.2166L17.9089 22.6131C17.6301 22.9451 17.1349 22.9882 16.8029 22.7094Z"
                    fill="#001E60"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.225033 19.6266C0.155763 19.1972 0.447477 18.7917 0.876595 18.7209L8.03428 17.5401C8.4634 17.4693 8.86742 17.76 8.93669 18.1893C9.00596 18.6187 8.71424 19.0242 8.28513 19.095L1.12744 20.2758C0.698324 20.3466 0.294302 20.0559 0.225033 19.6266Z"
                    fill="#001E60"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.73077 16.1344C5.6615 15.705 5.95321 15.2995 6.38233 15.2288L10.4288 14.5612C10.8579 14.4904 11.262 14.7811 11.3312 15.2104C11.4005 15.6398 11.1088 16.0453 10.6797 16.1161L6.63318 16.7836C6.20406 16.8544 5.80004 16.5637 5.73077 16.1344Z"
                    fill="#001E60"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.16803 21.5572C5.09876 21.1279 5.39047 20.7224 5.81959 20.6516L14.4315 19.2308C14.8606 19.1601 15.2646 19.4507 15.3339 19.8801C15.4032 20.3095 15.1115 20.7149 14.6823 20.7857L6.07043 22.2065C5.64132 22.2773 5.2373 21.9866 5.16803 21.5572Z"
                    fill="#001E60"
                />
            </svg>

            <span className="sr-only">Expand Alert</span>
        </button>
    );
};

SignUpBubble.propTypes = {
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
};

export default SignUpBubble;
